import { createApp } from 'vue'
import App from './App.vue'

import router from './router'
import './assets/scss/main.scss';

import VueFeather from 'vue-feather';

import i18n from './i18n'

const app = createApp(App);
app.use(i18n);
app.use(router);
app.component(VueFeather.name, VueFeather);
app.mount('#app');
