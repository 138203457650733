<template>
  <div class="navbar">
    <div class="brand">
      <img src="@/assets/images/asuaire-logo.svg" alt="Logo" />
    </div>
    <ul v-if="!mobile" class="navigation">
      <li>
        <a href="javascript:void(0)" @click="goToRoute('home')" class="link">{{
          $t("myTrip")
        }}</a>
      </li>
      <li>
        <a href="javascript:void(0)" @click="goToRoute('info')" class="link">{{
          $t("information")
        }}</a>
      </li>
      <li>
        <a href="javascript:void(0)" @click="goToRoute('about')" class="link">{{
          $t("aboutUs")
        }}</a>
      </li>
    </ul>
    <div
      :class="['icon', { active: mobileNav }]"
      v-if="mobile"
      @click="toggleNav"
    >
      <vue-feather type="menu"></vue-feather>
    </div>
    <div :class="['mobile-menu', { active: mobileNav }]">
      <div class="brand">
        <img src="@/assets/images/asuaire-logo.svg" alt="Logo" />
      </div>
      <ul>
        <li>
          <a
            href="javascript:void(0)"
            @click="goToRoute('home')"
            class="link"
            >{{ $t("myTrip") }}</a
          >
        </li>
        <li>
          <a
            href="javascript:void(0)"
            @click="goToRoute('info')"
            class="link"
            >{{ $t("information") }}</a
          >
        </li>
        <li>
          <a
            href="javascript:void(0)"
            @click="goToRoute('about')"
            class="link"
            >{{ $t("aboutUs") }}</a
          >
        </li>
      </ul>
    </div>
    <!-- </transition> -->
  </div>
</template>
<script>
import router from "@/router";
import { ref } from "vue";
import { useRoute } from "vue-router";
export default {
  name: "NavBar",
  setup() {
    let mobile = ref(false);
    let mobileNav = ref(false);

    const route = useRoute();

    const toggleNav = () => {
      mobileNav.value = !mobileNav.value;
    };
    const handleWindowResize = () => {
      if (window.innerWidth < 992) {
        mobile.value = true;
      } else {
        mobile.value = false;
      }
    };
    const goToRoute = (name) => {
      console.log("name", name, route.params.id);
      router.push({ name: name, params: { id: route.params.id } });
    };
    handleWindowResize();
    window.addEventListener("resize", handleWindowResize);

    return {
      mobile,
      mobileNav,
      toggleNav,
      goToRoute,
    };
  },
};
</script>
